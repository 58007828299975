import { useMemo } from "react";
import { Badge, Box, HStack, Text } from "@chakra-ui/react";
import { statuses } from "consts";

const useColorSchemeAndLabel = (children) => {
  return useMemo(() => {
    try {
      const { colorScheme, label } = statuses.invoices.find((o) => o.value === children);
      return [colorScheme, label];
    } catch (error) {
      return ["gray", "Não especificado"];
    }
  }, [children]);
};

export const InvoiceStatusBadge = ({ children, ...props }) => {
  const [colorScheme, label] = useColorSchemeAndLabel(children);
  return (
    <Badge colorScheme={colorScheme} py="8px" px="12px" borderRadius="lg" {...props}>
      <HStack spacing={2}>
        <Box w="8px" h="8px" bg={colorScheme.concat(".500")} borderRadius="full" />
        <Text>{label}</Text>
      </HStack>
    </Badge>
  );
};

export const InvoiceStatusBar = ({ children }) => {
  const [colorScheme] = useColorSchemeAndLabel(children);
  return <Box w="5px" h="30px" borderRadius="full" bg={colorScheme.concat(".500")} />;
};
