import React, { useCallback, useMemo, useState } from "react";
import { Button, Divider, Heading, HStack, Icon, SlideFade, Spinner, Text, VStack } from "@chakra-ui/react";
import _ from "lodash";
import { useCustomToast } from "hooks";
import { Logo } from "components";
import { api, masks } from "lib";
import { useLocation, useNavigate } from "react-router-dom";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineMailOutline } from "react-icons/md";

export const BillingContacts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoadingData, setIsLoadingData] = useState({});
  const toast = useCustomToast();
  const isDisabled = useMemo(() => Object.values(isLoadingData).includes(true), [isLoadingData]);

  const handleBillingContact = useCallback(
    async (email) => {
      try {
        setIsLoadingData((state) => ({ ...state, [email]: true }));
        const response = await api.post("/sign-in/billing-contacts", { _id: location.state._id, email });
        navigate("/verification-code", { state: response });
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
        setIsLoadingData((state) => ({ ...state, [email]: false }));
      }
    },
    [location.state, toast]
  );

  return (
    <SlideFade in={true} offsetY="20px" transition={{ enter: { duration: 0.6 } }} style={{ width: "90%", maxWidth: "420px" }}>
      <VStack justifyContent="center" alignItems="stretch" p="30px">
        <Logo width={100} />
        <Heading size="md" mt={8}>
          Escolha um dos e-mails
        </Heading>
        <Text opacity="0.6" fontSize="sm">
          Encontramos múltiplos contatos de faturamento para o documento informado. Por favor, selecione o e-mail que deseja utilizar para o
          login.
        </Text>
        <VStack alignItems="stretch" mt={4}>
          {_.map(location.state.emails, (email) => (
            <Button
              key={email}
              size="lg"
              fontSize="sm"
              borderRadius="xl"
              onClick={() => handleBillingContact(email)}
              leftIcon={<Icon as={MdOutlineMailOutline} />}
              rightIcon={isLoadingData[email] ? <Spinner size="xs" /> : <Icon as={MdOutlineKeyboardArrowRight} />}
              isDisabled={isDisabled}
            >
              <Text flex="1">{masks.obfuscateEmail(email)}</Text>
            </Button>
          ))}
        </VStack>
        <HStack my={6}>
          <Divider />
          <Text fontSize="xs" opacity="0.6">
            ou
          </Text>
          <Divider />
        </HStack>
        <Button
          size="sm"
          borderRadius="xl"
          variant="outline"
          textDecoration="none"
          leftIcon={<Icon as={MdOutlineKeyboardArrowLeft} />}
          onClick={() => navigate(-1)}
        >
          Voltar à página anterior
        </Button>
      </VStack>
    </SlideFade>
  );
};
