import React, { useCallback, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  PinInput,
  PinInputField,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCustomToast } from "hooks";
import { Logo } from "components";
import { messages } from "consts";
import * as yup from "yup";
import { masks } from "lib";
import { LuLogIn } from "react-icons/lu";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { AuthContext } from "AuthProvider";

export const VerificationCode = () => {
  const { signIn } = useContext(AuthContext);
  const [formData, setFormData] = useState({ verificationCode: "" });
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const toast = useCustomToast();
  const navigate = useNavigate();
  const location = useLocation();

  const handleVerificationCode = useCallback(
    async ({ verificationCode }) => {
      try {
        setIsLoadingData(true);
        await signIn({ _id: location.state._id, email: location.state.email, verificationCode });
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
        setIsLoadingData(false);
      }
    },
    [location.state, toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          verificationCode: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleVerificationCode(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [handleVerificationCode, formData]
  );

  return (
    <SlideFade in={true} offsetY="20px" transition={{ enter: { duration: 0.6 } }} style={{ width: "90%", maxWidth: "420px" }}>
      <form onSubmit={handleSubmit}>
        <VStack justifyContent="center" alignItems="stretch" p="30px">
          <Logo width={100} />
          <Heading size="md" mt={8}>
            Código de Verificação
          </Heading>
          <Text opacity="0.6" fontSize="sm">
            Por favor, informe o código de verificação enviado para o email <strong>{masks.obfuscateEmail(location.state.email)}</strong>.
          </Text>
          <FormControl isInvalid={formErrors.verificationCode} my={4}>
            <HStack justifyContent="center">
              <PinInput
                size="lg"
                onChange={(verificationCode) => setFormData((state) => ({ ...state, verificationCode }))}
                autoFocus={true}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
            <FormErrorMessage>{formErrors.verificationCode}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            size="lg"
            colorScheme="main"
            w="100%"
            borderRadius="xl"
            rightIcon={<Icon as={LuLogIn} />}
            fontSize="sm"
            isLoading={isLoadingData}
          >
            Entrar
          </Button>
          <HStack my={6}>
            <Divider />
            <Text fontSize="xs" opacity="0.6">
              ou
            </Text>
            <Divider />
          </HStack>
          <Button
            size="sm"
            borderRadius="xl"
            variant="outline"
            textDecoration="none"
            leftIcon={<Icon as={MdOutlineKeyboardArrowLeft} />}
            onClick={() => navigate(-1)}
          >
            Voltar à página anterior
          </Button>
        </VStack>
      </form>
    </SlideFade>
  );
};
