import React, { Fragment, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Box, Button, Center, Icon, SlideFade, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import moment from "moment";
import { currency } from "lib";
import { InvoiceStatusBadge, OverlaySpinner } from "components";
import { useBankSlip, useClipboard, useDocumentTitle, useFetchData } from "hooks";
import { MdPix } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Partnership } from "./partnership";
import { ConsumerUnit } from "./consumerUnit";
import InvoicesListContext from "./context";
import { messages } from "consts";
import LatestInvoice from "./latestInvoice";

export const Invoices = () => {
  useDocumentTitle("Minhas faturas");
  const [consumerUnit, setConsumerUnit] = useState({});
  const [invoices, isLoadingInvoices] = useFetchData(
    useMemo(
      () => ({
        path: "/invoices",
        params: { query: { consumerUnit: consumerUnit?._id } },
        options: { isEnabled: _.isString(consumerUnit?._id) },
      }),
      [consumerUnit?._id]
    )
  );
  const [latestInvoice, setLatestInvoice] = useState();
  const [getBankSlip, isLoadingBankSlip] = useBankSlip();
  const copyToClipboard = useClipboard();

  useEffect(() => {
    if (_.size(invoices) >= 1) {
      const [latestInvoice] = invoices;
      setLatestInvoice(latestInvoice);
    }
  }, [invoices]);

  return (
    <InvoicesListContext.Provider value={{ consumerUnit, setConsumerUnit }}>
      <SlideFade in={true} offsetY="20px" transition={{ enter: { duration: 0.6, delay: 0.3 } }}>
        <VStack alignItems="stretch" maxW="1140px" mt="-30px" mx="auto" spacing={2}>
          <Partnership />
          <ConsumerUnit />
          {isLoadingInvoices && (
            <Box position="relative" borderRadius="xl" bg="white" h="200px">
              <OverlaySpinner />
            </Box>
          )}
          {consumerUnit._id && !isLoadingInvoices && (
            <Fragment>
              {latestInvoice && <LatestInvoice data={latestInvoice} />}
              <Box bg="white" borderRadius="xl" p={{ base: "20px", lg: "30px" }} overflowX="auto">
                <Text mb="15px">Lista de faturas</Text>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Status</Th>
                      <Th>Referência</Th>
                      <Th>Vencimento</Th>
                      <Th>Consumo</Th>
                      <Th>Valor</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {_.map(invoices, (item) => (
                      <Tr key={item._id}>
                        <Td>
                          <VStack>
                            <Button
                              w="100%"
                              size="sm"
                              leftIcon={<Icon as={IoDocumentTextOutline} />}
                              isLoading={isLoadingBankSlip[item._id]}
                              onClick={() => getBankSlip(item._id)}
                            >
                              fatura detalhada
                            </Button>
                            {_.includes(["created", "updated"], item.status) && (
                              <Button
                                w="100%"
                                size="sm"
                                colorScheme="green"
                                leftIcon={<Icon as={MdPix} />}
                                onClick={() => copyToClipboard(item.charge?.pixInfo?.emv, messages.success.copyPix)}
                              >
                                copiar código Pix
                              </Button>
                            )}
                          </VStack>
                        </Td>
                        <Td>
                          <InvoiceStatusBadge>{item.status}</InvoiceStatusBadge>
                        </Td>
                        <Td>{moment(item.referenceDate).format("MMM/YYYY").toUpperCase()}</Td>
                        <Td>{moment(item.dueDate).format("DD/MM/YYYY")}</Td>
                        <Td>{item.consumedPowerQttInKwh.toLocaleString()} kWh</Td>
                        <Td fontWeight="bold">{currency(item.receivableAmount)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {isLoadingInvoices && (
                  <Center p="40px">
                    <Spinner />
                  </Center>
                )}
              </Box>
            </Fragment>
          )}
        </VStack>
      </SlideFade>
    </InvoicesListContext.Provider>
  );
};
