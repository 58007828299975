import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { Box, Button, Heading, HStack, Icon, IconButton, SlideFade } from "@chakra-ui/react";
import { GoSignOut } from "react-icons/go";
import { MdMenu } from "react-icons/md";
import { AuthContext } from "AuthProvider";
import { AppContext } from "AppProvider";
import ContainerContext from "./context";
import { EventEmitter } from "lib";
import { useCustomToast } from "hooks";

export const Toolbar = () => {
  const location = useLocation();
  const { isMobileView } = useContext(AppContext);
  const { signOut } = useContext(AuthContext);
  const { onOpenSidebar } = useContext(ContainerContext);
  const [isLoadingSignOut, setIsLoadingSignOut] = useState(false);
  const [title, setTitle] = useState();
  const toast = useCustomToast();

  useEffect(() => {
    const listener = EventEmitter.addListener("documentTitle", (documentTitle) => setTitle(documentTitle));
    return () => listener.remove();
  }, []);

  const handleSignOut = useCallback(async () => {
    try {
      setIsLoadingSignOut(true);
      await signOut();
    } catch (error) {
      toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
      setIsLoadingSignOut(false);
    }
  }, [signOut]);

  return (
    <SlideFade key={location.pathname} in={true} offsetY="20px" transition={{ enter: { duration: 0.6 } }}>
      <Box p="30px" pt="40px" pb="60px" bg="green.500" borderRadius="xl">
        <HStack maxW="1140px" mx="auto">
          {isMobileView && <IconButton colorScheme="whiteAlpha" icon={<Icon as={MdMenu} />} onClick={onOpenSidebar} />}
          <Heading size="lg" color="white" flex="1">
            {title}
          </Heading>
          <Button colorScheme="whiteAlpha" leftIcon={<Icon as={GoSignOut} />} isLoading={isLoadingSignOut} onClick={handleSignOut}>
            Sair
          </Button>
        </HStack>
      </Box>
    </SlideFade>
  );
};
