import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Container from "./Container";
import { Invoices } from "./Invoices";
import { Questions } from "./Questions";

const Private = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} path="invoices" element={<Invoices />} />
          <Route index={true} path="questions" element={<Questions />} />
        </Route>
        <Route path="*" element={<Navigate to="/invoices" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Private;
