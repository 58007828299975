import React, { Fragment, useMemo } from "react";
import _ from "lodash";
import { Box, Center, Grid, GridItem, HStack, Icon, Text } from "@chakra-ui/react";
import { useFetchData } from "hooks";
import { currency, locale } from "lib";
import { AnimatedNumber, OverlaySpinner } from "components";
import { MdOutlineAttachMoney, MdBolt } from "react-icons/md";
import { PiPlantFill } from "react-icons/pi";

export const Partnership = () => {
  const [partnership, isLoadingPartnership] = useFetchData(useMemo(() => ({ path: "/partnership" }), []));

  return (
    <Fragment>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem
          colSpan={{ base: 12, lg: 4 }}
          as={HStack}
          position="relative"
          alignItems="center"
          justifyContent="center"
          bg="white"
          p="20px"
          borderRadius="xl"
        >
          {isLoadingPartnership && <OverlaySpinner />}
          <HStack flex="1" justifyContent="flex-end">
            <Center w="50px" h="70px" borderRadius="full" bg="gray.100">
              <Icon as={MdOutlineAttachMoney} boxSize="25px" color="green.500" />
            </Center>
          </HStack>
          <Box flex="2">
            <Text fontSize="xs">Sua economia foi de</Text>
            <AnimatedNumber fontSize="2xl" fontWeight="semibold" value={partnership?.economyAmount || 0} to={(v) => currency(v)} />
            <Text fontSize="xs">até o dia de hoje</Text>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, lg: 4 }}
          as={HStack}
          position="relative"
          alignItems="center"
          justifyContent="center"
          bg="white"
          p="20px"
          borderRadius="xl"
        >
          {isLoadingPartnership && <OverlaySpinner />}
          <HStack flex="1" justifyContent="flex-end">
            <Center w="50px" h="70px" borderRadius="full" bg="gray.100">
              <Icon as={PiPlantFill} boxSize="25px" color="green.500" />
            </Center>
          </HStack>
          <Box flex="2">
            <Text fontSize="xs">Você deixou de emitir</Text>
            <AnimatedNumber
              fontSize="2xl"
              fontWeight="semibold"
              value={partnership?.co2ReductionInKg || 0}
              to={(v) => locale(v, { precision: 0 }).concat(" kg")}
            />
            <Text fontSize="xs">de CO₂ na natureza</Text>
          </Box>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, lg: 4 }}
          as={HStack}
          position="relative"
          alignItems="center"
          justifyContent="center"
          bg="white"
          p="20px"
          borderRadius="xl"
        >
          {isLoadingPartnership && <OverlaySpinner />}
          <HStack flex="1" justifyContent="flex-end">
            <Center w="50px" h="70px" borderRadius="full" bg="gray.100">
              <Icon as={MdBolt} boxSize="25px" color="green.500" />
            </Center>
          </HStack>
          <Box flex="2">
            <Text fontSize="xs">Você consumiu em média</Text>
            <AnimatedNumber
              fontSize="2xl"
              fontWeight="semibold"
              value={partnership?.avgConsumedPowerQttInKwh || 0}
              to={(v) => locale(v, { precision: 0 }).concat(" kWh")}
            />
            <Text fontSize="xs">por mês</Text>
          </Box>
        </GridItem>
      </Grid>
    </Fragment>
  );
};
