import { api } from "lib";
import { useCallback, useState } from "react";
import { useCustomToast } from "./useCustomToast";

export const useBankSlip = () => {
  const [isLoadingBankSlip, setIsLoadingBankSlip] = useState({});
  const toast = useCustomToast();

  const getBankSlip = useCallback(async (_id) => {
    try {
      setIsLoadingBankSlip((state) => ({ ...state, [_id]: true }));
      const response = await api({
        method: "post",
        url: `/invoices/${_id}/bank-slip`,
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      });
      const file = new Blob([response], { type: "application/pdf" });
      window.location.href = window.URL.createObjectURL(file);
    } catch (arrayBufferError) {
      const error = JSON.parse(new TextDecoder().decode(arrayBufferError));
      toast({ description: error.message, status: "error", isClosable: true });
    } finally {
      setIsLoadingBankSlip((state) => ({ ...state, [_id]: false }));
    }
  }, []);

  return [getBankSlip, isLoadingBankSlip];
};
