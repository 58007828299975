import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import moment from "moment";
import QRCode from "qrcode.react";
import { Box, Button, Center, CircularProgress, Grid, GridItem, Heading, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { currency, percent, locale } from "lib";
import { AnimatedNumber, InvoiceStatusBadge } from "components";
import { useBankSlip, useClipboard } from "hooks";
import { MdPix } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { messages } from "consts";

const ConsumeCircle = ({ data }) => {
  const [progress, setProgress] = useState(0);
  const traderEletricPower = useMemo(
    () =>
      _(data.items)
        .filter((o) => o.type === "eletric_power")
        .map("powerQttInKwh")
        .sum(),
    [data.items]
  );
  const distributorEletricPower = useMemo(
    () => data.consumedPowerQttInKwh - traderEletricPower,
    [data.consumedPowerQttInKwh, traderEletricPower]
  );
  const percentage = useMemo(() => {
    try {
      return (traderEletricPower / data.consumedPowerQttInKwh) * 100;
    } catch (error) {
      return 0;
    }
  }, [data.consumedPowerQttInKwh, traderEletricPower]);

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
      <GridItem colSpan={{ base: 12, lg: 6 }} as={VStack} alignItems={{ base: "center", lg: "flex-end" }} justifyContent="center">
        <CircularProgress value={progress} size="180px" thickness="8px" color="green.500" capIsRound>
          <Center w="180px" h="180px" borderRadius="full" p="20px" position="absolute" top="0" fontSize="sm">
            <VStack spacing={0}>
              <Text fontSize="xs">Você</Text>
              <Text fontSize="xs">consumiu</Text>
              <AnimatedNumber
                fontSize="2xl"
                fontWeight="semibold"
                value={parseInt(data.consumedPowerQttInKwh || 0)}
                to={(v) => locale(v, { precision: 0 })}
              />
              <Text fontWeight="semibold">kWh</Text>
            </VStack>
          </Center>
        </CircularProgress>
      </GridItem>
      <GridItem colSpan={{ base: 12, lg: 6 }} as={VStack} alignItems="stretch" justifyContent="center">
        <HStack>
          <Box w="8px" h="40px" borderRadius="full" bg="green.500" />
          <Box>
            <Text>
              <strong>{traderEletricPower.toLocaleString()} kWh</strong> ClickLivre Energia
            </Text>
            <Text color="green.500" fontWeight="bold" fontSize="sm">
              {percent(data.discountPercentage, { precision: 0 })} de desconto
            </Text>
          </Box>
        </HStack>
        <HStack>
          <Box w="8px" h="40px" borderRadius="full" bg="gray.300" />
          <Text>
            <strong>{distributorEletricPower.toLocaleString()} kWh</strong> Concessionária
          </Text>
        </HStack>
      </GridItem>
    </Grid>
  );
};

const LatestInvoice = ({ data }) => {
  const [getBankSlip, isLoadingBankSlip] = useBankSlip();
  const copyToClipboard = useClipboard();
  const isPending = useMemo(() => _.includes(["created", "updated"], data.status), [data.status]);

  return (
    <Box bg="white" borderRadius="xl" p={{ base: "20px", lg: "30px" }}>
      <HStack mb={4}>
        <Text flex="1">Fatura mais recente</Text>
        <InvoiceStatusBadge size="lg">{data.status}</InvoiceStatusBadge>
      </HStack>

      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="xl" p="20px" textAlign="center">
          <Text fontSize="sm">Referente a</Text>
          <Heading size="md">{moment(data.referenceDate).format("MMM/YYYY").toUpperCase()}</Heading>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="xl" p="20px" textAlign="center">
          <Text fontSize="sm">Vencimento</Text>
          <Heading size="md">{moment(data.dueDate).format("DD/MM/YYYY")}</Heading>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="xl" p="20px" textAlign="center">
          <Text fontSize="sm">Sua economia foi de</Text>
          <Heading size="md">{currency(data.economyAmount)}</Heading>
        </GridItem>
        <GridItem colSpan={{ base: 12, lg: 3 }} borderWidth="1px" borderRadius="xl" p="20px" textAlign="center" bg="black" color="white">
          <Text fontSize="sm">Total {isPending ? "a pagar" : "pago"}</Text>
          <Heading size="md">{currency(data.receivableAmount)}</Heading>
        </GridItem>

        <GridItem colSpan={{ base: 12, lg: isPending ? 6 : 12 }} bg="gray.50" p="20px" borderRadius="xl" as={Center}>
          <ConsumeCircle data={data} />
        </GridItem>

        {isPending && (
          <GridItem colSpan={{ base: 12, lg: 6 }}>
            <HStack
              alignItems="center"
              p="20px"
              borderWidth="1px"
              borderRadius="xl"
              cursor="pointer"
              _hover={{ bg: "gray.100" }}
              transition="200ms"
              onClick={() => copyToClipboard(data?.charge?.pixInfo?.emv, messages.success.copyPix)}
              mb={2}
              wrap={{ base: "wrap", lg: "nowrap" }}
              justifyContent={{ base: "center", lg: "space-between" }}
              spacing={{ base: 4, lg: 2 }}
            >
              <HStack>
                <Icon as={MdPix} boxSize="30px" />
                <Box flex="1">
                  <Text>Pague com o Pix</Text>
                  <Text fontWeight="semibold" textDecoration="underline">
                    Copiar o código Pix
                  </Text>
                </Box>
              </HStack>
              <QRCode value={data?.charge?.pixInfo?.emv} size={140} />
            </HStack>
            <Button
              w="100%"
              size="lg"
              colorScheme="green"
              leftIcon={<Icon as={IoDocumentTextOutline} />}
              isLoading={isLoadingBankSlip[data._id]}
              onClick={() => getBankSlip(data._id)}
            >
              Abrir fatura detalhada
            </Button>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};

export default LatestInvoice;
