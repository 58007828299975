import React, { Fragment, useContext, useEffect, useMemo } from "react";
import _ from "lodash";
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFetchData } from "hooks";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { TbHomeBolt } from "react-icons/tb";
import InvoicesListContext from "./context";

export const ConsumerUnit = () => {
  const { consumerUnit, setConsumerUnit } = useContext(InvoicesListContext);
  const [consumerUnits, isLoadingConsumerUnits] = useFetchData(useMemo(() => ({ path: "/consumer-units" }), []));

  useEffect(() => {
    setConsumerUnit((state) => {
      try {
        if (state._id) return state;
        const [consumerUnit] = consumerUnits;
        return consumerUnit;
      } catch (error) {
        return {};
      }
    });
  }, [consumerUnits]);

  return (
    <Fragment>
      <Grid templateColumns="repeat(12, 1fr)" gap={4} position="relative" bg="white" p="10px" borderRadius="xl" spacing="20px">
        {isLoadingConsumerUnits && (
          <Center position="absolute" w="100%" h="100%" top="0" left="0" bg="white" borderRadius="xl" zIndex="1">
            <Spinner />
          </Center>
        )}
        <GridItem colSpan={{ base: 12, lg: 3 }}>
          <Menu>
            <MenuButton
              w="100%"
              as={Button}
              leftIcon={
                <Center w="30px" h="30px" borderRadius="full" bg="blackAlpha.100">
                  <Icon as={TbHomeBolt} />
                </Center>
              }
              rightIcon={<Icon as={MdKeyboardArrowDown} />}
              h="auto"
              px="20px"
              py="15px"
            >
              <Box flex="1" textAlign="left">
                <Text fontSize="x-small" fontWeight="light">
                  Instalação
                </Text>
                <Text>{consumerUnit.cemigInstallationNumber || "-"}</Text>
              </Box>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                type="radio"
                value={consumerUnit._id}
                onChange={(value) => setConsumerUnit(_.find(consumerUnits, (o) => o._id === value) || {})}
              >
                {_.map(consumerUnits, (item) => (
                  <MenuItemOption key={item._id} value={item._id}>
                    <Text fontWeight="semibold">Instalação: {item.cemigInstallationNumber}</Text>
                    <Text fontSize="xs">{item.address?.line1 || "-"}</Text>
                    <Text fontSize="xs">{item.address?.line2 || "-"}</Text>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, lg: 9 }}
          as={VStack}
          spacing={0}
          p={{ base: 4, lg: 0 }}
          pt={0}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Text fontSize="md">{consumerUnit.address?.line1}</Text>
          <Text fontSize="sm" opacity="0.6">
            {consumerUnit.address?.line2}
          </Text>
        </GridItem>
      </Grid>
    </Fragment>
  );
};
