import React, { createContext, useContext } from "react";
import _ from "lodash";
import { useMediaQuery } from "@chakra-ui/react";
import { Private, Public } from "pages";
import { NetworkError, PermissionDenied, AppVersionError } from "exceptions";
import { AuthContext } from "AuthProvider";

export const AppContext = createContext();

const AppProvider = () => {
  const { currentUser } = useContext(AuthContext);
  const [isMobileView] = useMediaQuery("(max-width: 940px)");

  return (
    <AppContext.Provider value={{ isMobileView }}>
      {currentUser ? <Private /> : <Public />}
      <NetworkError />
      <PermissionDenied />
      <AppVersionError />
    </AppContext.Provider>
  );
};

export default AppProvider;
