import React from "react";
import { BrowserRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { SignIn } from "./SignIn";
import { Center, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import BackgroundImage from "assets/imgs/sign-in.jpg";
import { VerificationCode } from "./VerificationCode";
import { BillingContacts } from "./BillingContacts";

const background = {
  backgroundImage: `url(${BackgroundImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const Container = () => {
  return (
    <Grid templateColumns="repeat(12, 1fr)">
      <GridItem colSpan={{ base: 12, lg: 6 }} h="100vh" p="10px" pl={{ base: "10px", lg: "0" }} transform="scaleX(-1)" {...background}>
        <VStack w="100%" h="100%" alignItems="stretch" bg="white" borderRadius="xl" transform="scaleX(-1)">
          <Center flex="1">
            <Outlet />
          </Center>
          <VStack spacing={0} mb={6}>
            <Text fontSize="xs">&copy; Todos os direitos reservados | ClickLivre {new Date().getFullYear()}.</Text>
            <Text fontSize="x-small">v{process.env.REACT_APP_VERSION}</Text>
          </VStack>
        </VStack>
      </GridItem>
      <GridItem h="100vh" display={{ base: "none", lg: "block" }} {...background} colSpan={6} />
    </Grid>
  );
};

const Public = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Container />}>
          <Route index={true} element={<SignIn />} />
          <Route path="billing-contacts" element={<BillingContacts />} />
          <Route path="verification-code" element={<VerificationCode />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Public;
