import { useMemo, useState } from "react";
import _ from "lodash";
import Fuse from "fuse.js";
import {
  Box,
  Button,
  Center,
  FormControl,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  SlideFade,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useDocumentTitle, useFetchData } from "hooks";
import { OverlaySpinner } from "components";
import { MdAdd, MdRemove, MdSearch, MdWhatsapp } from "react-icons/md";
import { HiPhone } from "react-icons/hi";
import { FaEnvelope } from "react-icons/fa";

export const Questions = () => {
  useDocumentTitle("Dúvidas");
  const [questions, isLoadingQuestions] = useFetchData(useMemo(() => ({ path: "/faq" }), []));
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState({});
  const results = useMemo(() => {
    if (searchText.length === 0) return questions;
    const options = { keys: ["title"], includeScore: true };
    const fuse = new Fuse(questions, options);
    const results = fuse.search(searchText);
    return results.map(({ item }) => item);
  }, [questions, searchText]);

  return (
    <SlideFade in={true} offsetY="20px" transition={{ enter: { duration: 0.6, delay: 0.3 } }}>
      <VStack alignItems="stretch" maxW="1140px" mt="-30px" mx="auto" spacing={2}>
        <Box bg="white" p="10px" borderRadius="xl">
          <FormControl>
            <InputGroup size="lg" variant="filled">
              <InputLeftElement>
                <Icon as={MdSearch} />
              </InputLeftElement>
              <Input
                borderRadius="xl"
                placeholder="Digite sua dúvida..."
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
              />
            </InputGroup>
          </FormControl>
        </Box>

        <VStack alignItems="stretch" position="relative" bg="white" p="20px" borderRadius="xl" divider={<StackDivider />} minH="200px">
          {isLoadingQuestions && <OverlaySpinner />}
          {_.map(results, (item) => (
            <Box key={item._id}>
              <HStack
                p="20px"
                cursor="pointer"
                transition="200ms"
                color={isOpen[item._id] && "green.500"}
                _hover={{ color: "green.500" }}
                onClick={() => setIsOpen((state) => ({ ...state, [item._id]: !state[item._id] }))}
              >
                <Heading size="md" flex="1">
                  {item.title}
                </Heading>
                <Icon as={isOpen[item._id] ? MdRemove : MdAdd} boxSize="25px" />
              </HStack>
              {isOpen[item._id] && (
                <Box p="20px" pt="0">
                  <Text>{item.content}</Text>
                </Box>
              )}
            </Box>
          ))}
        </VStack>

        <Box bg="white" p={{ base: "30px", lg: "60px" }} borderRadius="xl" borderWidth="2px" borderColor="green.500">
          <VStack spacing={0} mb="40px">
            <Heading size="lg">Canais de atendimento</Heading>
            <Heading size="md" fontWeight="light">
              Segunda a sexta de 08h às 18h
            </Heading>
          </VStack>
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={{ base: 12, lg: 4 }} py="20px">
              <HStack>
                <Icon as={MdWhatsapp} boxSize="25px" />
                <Heading size="md">WhatsApp</Heading>
              </HStack>
              <Text fontSize="sm" my={4}>
                O WhatsApp é nosso principal canal de atendimento! Tire suas dúvidas e resolva o que precisar com a gente.
              </Text>
              <Button
                colorScheme="green"
                onClick={() => window.open("https://wa.me/5531989569405?text=Olá, sou cliente *ClickLivre* e tenho algumas dúvidas!")}
              >
                Conversar no WhatsApp
              </Button>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }} py="20px">
              <HStack>
                <Icon as={HiPhone} boxSize="25px" />
                <Heading size="md">Telefone</Heading>
              </HStack>
              <Text fontSize="sm" my={4}>
                Alguma coisa importante que é melhor falar pelo telefone? Liga para a gente discando o número abaixo.
              </Text>
              <Button as={Link} variant="outline" href="tel:+553136534993" _hover={{ textDecoration: "none", color: "main.500" }}>
                (31) 3653-4993
              </Button>
            </GridItem>

            <GridItem colSpan={{ base: 12, lg: 4 }} py="20px">
              <HStack>
                <Icon as={FaEnvelope} boxSize="25px" />
                <Heading size="md">E-mail</Heading>
              </HStack>
              <Text fontSize="sm" my={4}>
                Prefere um contato mais formal, e deixar tudo registrado por e-mail? Use o endereço abaixo.
              </Text>
              <Button as={Link} variant="outline" href="mailto:contato@clicklivre.com.br">
                contato@clicklivre.com.br
              </Button>
            </GridItem>
          </Grid>
        </Box>
      </VStack>
    </SlideFade>
  );
};
