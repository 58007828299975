import React, { useCallback, useState } from "react";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCustomToast } from "hooks";
import { Logo } from "components";
import { messages } from "consts";
import * as yup from "yup";
import { LuLogIn } from "react-icons/lu";
import { api } from "lib";
import { useNavigate } from "react-router-dom";

const signUpLink = "https://clicklivre.com.br/quero-economizar";

export const SignIn = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ document: "" });
  const [formErrors, setFormErrors] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const toast = useCustomToast();

  const handleSignIn = useCallback(
    async ({ document }) => {
      try {
        setIsLoadingData(true);
        const response = await api.post("/sign-in", { document });
        if (response.email) navigate("/verification-code", { state: response });
        else navigate("/billing-contacts", { state: response });
      } catch (error) {
        if (error.isHandled) return;
        toast({ description: error.message, status: "error", duration: 5000, isClosable: true });
        setIsLoadingData(false);
      }
    },
    [toast]
  );

  const handleSubmit = useCallback(
    async (e) => {
      try {
        e.preventDefault();
        const schema = yup.object().shape({
          document: yup.string().required(messages.error.required),
        });
        await schema.validate(formData, { abortEarly: false });
        handleSignIn(formData);
        setFormErrors({});
      } catch (error) {
        const formErrors = _.mapValues(_.keyBy(error.inner, "path"), "message");
        setFormErrors(formErrors);
      }
    },
    [handleSignIn, formData]
  );

  return (
    <SlideFade in={true} offsetY="20px" transition={{ enter: { duration: 0.6 } }} style={{ width: "90%", maxWidth: "420px" }}>
      <form onSubmit={handleSubmit}>
        <VStack justifyContent="center" alignItems="stretch" p="30px">
          <Logo width={100} />
          <Heading size="md" mt={8}>
            Que bom ter você por aqui!
          </Heading>
          <Text opacity="0.6" fontSize="sm">
            Faça login abaixo ou <Link href={signUpLink}>torne-se cliente ClickLivre</Link>.
          </Text>
          <FormControl isRequired={true} isInvalid={formErrors.document} my={4}>
            <Input
              as={CpfCnpj}
              variant="filled"
              size="lg"
              placeholder="Informe seu CPF ou CNPJ"
              value={formData.document || ""}
              onChange={({ target }) => setFormData((state) => ({ ...state, document: target.value }))}
              fontSize="sm"
              borderRadius="xl"
            />
            <FormErrorMessage>{formErrors.document}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            size="lg"
            colorScheme="main"
            w="100%"
            borderRadius="xl"
            rightIcon={<Icon as={LuLogIn} />}
            fontSize="sm"
            isLoading={isLoadingData}
          >
            Entrar
          </Button>
          <HStack my={6}>
            <Divider />
            <Text fontSize="xs" whiteSpace="nowrap" opacity="0.6">
              Ainda não possui uma conta?
            </Text>
            <Divider />
          </HStack>
          <Button
            as={Link}
            href={signUpLink}
            size="sm"
            borderRadius="xl"
            variant="outline"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
          >
            Ainda não sou cliente
          </Button>
        </VStack>
      </form>
    </SlideFade>
  );
};
