import React, { createContext, useMemo, useCallback, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { api, EventEmitter } from "lib";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState(Date.now());
  const currentUser = useMemo(() => {
    try {
      const token = localStorage.getItem("@token");
      return jwtDecode(token);
    } catch (error) {
      return null;
    }
  }, [timestamp]);

  const signIn = useCallback(async ({ _id, email, verificationCode }) => {
    const token = await api.post("/sign-in/verification-code", { _id, email, verificationCode });
    localStorage.setItem("@token", token);
    setTimestamp(Date.now());
  }, []);

  const signOut = useCallback(async () => {
    try {
      await api.post("/sign-out");
    } finally {
      localStorage.removeItem("@token");
      setTimestamp(Date.now());
    }
  }, []);

  useEffect(() => {
    const listener = EventEmitter.addListener("onAuthorizationError", signOut);
    return () => listener.remove();
  }, [signOut]);

  return <AuthContext.Provider value={{ currentUser, signIn, signOut }}>{children}</AuthContext.Provider>;
};
