import React from "react";
import _ from "lodash";
import { Text } from "@chakra-ui/react";
import { useSpring, animated } from "@react-spring/web";

export const AnimatedNumber = ({ value, to, ...props }) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: value },
    config: { duration: 1000 },
  });

  return (
    <Text {...props}>
      <animated.span>{number.to(to)}</animated.span>
    </Text>
  );
};
