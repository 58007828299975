import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Flex, Text, useColorModeValue, useDisclosure, useToken, VStack } from "@chakra-ui/react";
import { Toolbar } from "./toolbar";
import Sidebar from "./sidebar";
import ContainerContext from "./context";

const Container = () => {
  const [innerHeight, setInnerHeight] = useState("100vh");
  const [themeColor] = useToken("colors", [useColorModeValue("gray.50", "gray.900")]);
  const { isOpen: isOpenSidebar, onOpen: onOpenSidebar, onClose: onCloseSidebar } = useDisclosure();

  useEffect(() => {
    const onInnerHeight = () => setInnerHeight(`${window.innerHeight}px`);
    onInnerHeight();
    window.addEventListener("resize", onInnerHeight);
    window.addEventListener("orientationchange", onInnerHeight);
    return () => {
      window.removeEventListener("resize", onInnerHeight);
      window.removeEventListener("orientationchange", onInnerHeight);
    };
  }, []);

  useEffect(() => {
    document.querySelector("meta[name='theme-color']").setAttribute("content", themeColor);
  }, [themeColor]);

  return (
    <ContainerContext.Provider value={{ isOpenSidebar, onOpenSidebar, onCloseSidebar }}>
      <Flex direction="row" w="100vw" h={innerHeight} bg="gray.100">
        <Sidebar />
        <Flex flex="1" direction="column" p="10px" overflowY="auto">
          <Toolbar />
          <Outlet />
          <VStack spacing={0} my={6}>
            <Text>&copy; Todos os direitos reservados | ClickLivre {new Date().getFullYear()}.</Text>
            <Text fontSize="xs">v{process.env.REACT_APP_VERSION}</Text>
          </VStack>
        </Flex>
      </Flex>
    </ContainerContext.Provider>
  );
};

export default Container;
