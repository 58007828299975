import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "consts";
import moment from "moment";
import "moment/locale/pt-br";
import AppProvider from "./AppProvider";
import { AuthProvider } from "AuthProvider";

moment.locale("pt-br");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <AuthProvider>
      <AppProvider />
    </AuthProvider>
  </ChakraProvider>
);
