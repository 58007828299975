import { useContext, useEffect, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Icon,
  IconButton,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Logo } from "components";
import { TbFileInvoice } from "react-icons/tb";
import { IoChatbubblesOutline } from "react-icons/io5";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import ContainerContext from "./context";
import { AppContext } from "AppProvider";
import { MdClose } from "react-icons/md";

const ListItem = ({ icon, children, onClick, ...props }) => {
  const baseProps = useMemo(() => {
    if (onClick instanceof Function) return { onClick };
    return { as: NavLink, style: ({ isActive }) => (isActive ? { backgroundColor: "black", color: "white" } : {}) };
  }, [onClick]);

  return (
    <Button size="lg" variant="ghost" leftIcon={<Icon as={icon} />} {...baseProps} {...props}>
      <Text flex="1" textAlign="left">
        {children}
      </Text>
    </Button>
  );
};

const SidebarList = () => {
  return (
    <Box h="100%" w="100%" p="20px" bg="white" borderRadius="xl">
      <VStack alignItems="stretch" h="100%">
        <Center my="20px">
          <Logo width={100} />
        </Center>
        <ListItem icon={TbFileInvoice} to="invoices">
          Faturas
        </ListItem>
        <ListItem icon={AiOutlineQuestionCircle} to="questions">
          Dúvidas
        </ListItem>
        <Box flex="1" />
        <VStack borderWidth="1px" borderColor="green.500" borderRadius="xl" p="20px" alignItems="stretch" spacing={3}>
          <HStack>
            <Center w="30px" h="30px" borderRadius="full" bg="green.100">
              <Icon as={IoChatbubblesOutline} color="green.500" />
            </Center>
            <Text fontSize="sm" fontWeight="semibold">
              Precisa de ajuda?
            </Text>
          </HStack>
          <Button
            size="sm"
            colorScheme="green"
            onClick={() => window.open("https://wa.me/5531989569405?text=Olá, sou cliente *ClickLivre* e tenho algumas dúvidas!")}
          >
            Fale com a gente
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isMobileView } = useContext(AppContext);
  const { isOpenSidebar, onCloseSidebar } = useContext(ContainerContext);

  useEffect(() => {
    onCloseSidebar();
  }, [location.pathname]);

  return isMobileView ? (
    <Drawer isOpen={isOpenSidebar} placement="left" onClose={onCloseSidebar}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody p="0" h="100%">
          <IconButton position="absolute" left="20px" top="20px" variant="outline" icon={<Icon as={MdClose} />} onClick={onCloseSidebar} />
          <SidebarList />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <Box maxW="260px" h="100%" p="10px" pr="0">
      <SlideFade in={true} offsetX="-20px" offsetY="0" transition={{ enter: { duration: 0.6 } }} style={{ height: "100%" }}>
        <SidebarList />
      </SlideFade>
    </Box>
  );
};

export default Sidebar;
